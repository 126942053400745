import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../layout/Layout'
import { Row, Column } from '../layout/Grid'
import Button from '../components/Button'
import DoubleTitle from '../components/DoubleTitle'
import * as Styled from '../components/Career/post.styles'
import SectionBenefits from '../components/SectionBenefits'
import SectionFileForm from '../components/SectionFileForm'
import IconMarker from '../images/icons/marker-light.svg'

const CareerPostTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout
      metaTitle={`Kariera - ${frontmatter.title}`}
      metaDescription="Jesteśmy firmą, która od wielu lat zapewnia Klientom krajowy i międzynarodowy transport drogowy w standardowym i specjalistycznym zakresie."
      slug={`/kariera/${frontmatter.slug}`}
      reverseHeaderColors
    >
      <Row column mobileNoSpacing>
        <Styled.HeaderImage
          component="div"
          image={getImage(frontmatter.header)}
        >
          <IconMarker />
        </Styled.HeaderImage>
      </Row>
      <Styled.Section id="czytaj-wiecej">
        <Row alignBottom>
          <Column xs={12} lg={5} xl={4} largeOffset={1}>
            <DoubleTitle
              smallTitle="Kariera"
              title={frontmatter.title}
              component="h1"
              variant="h3"
            />
            <Styled.City>
              Miejsce pracy: <br />
              Bydgoszcz lub Toruń
            </Styled.City>
            <p className="lead">
              Zacznij pracę w Nordic Logistics Polska! Masz doświadczenie
              w&nbsp;telefonicznej obsłudze klienta, ale nie chcesz pracować
              w&nbsp;kolejnym Call Center?
            </p>
            <p className="lead">
              Chcesz żeby każdy Twój dzień pracy był inny? <br />
              Szukasz pracy pełnej wyzwań?
            </p>
            <p className="lead">Zapraszamy do złożenia aplikacji!</p>
          </Column>
          <Column xs={12} lg={5} xl={6} largeOffset={1}>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            />
          </Column>
        </Row>
        <Row alignRight>
          <Column xs={12} lg={6}>
            <Button scroll="#aplikuj" primary>
              Aplikuj
            </Button>
          </Column>
        </Row>
      </Styled.Section>
      <SectionBenefits />
      <SectionFileForm job={frontmatter.title} />
    </Layout>
  )
}

export default CareerPostTemplate

export const pageQuery = graphql`
  query CareerPostBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        id
        title
        slug
        header {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
