import styled, { css } from 'styled-components'
import { BgImage } from 'gbimage-bridge'

const HeaderImage = styled(BgImage)`
  position: relative;
  padding-top: 100%;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding-top: 0;
    margin-top: 120px;
    height: calc(100vh - 200px);
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    margin-top: 150px;
  }

  svg {
    position: absolute;
    bottom: -80px;
    right: -80px;
    width: 240px;
    height: auto;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      width: 400px;
      bottom: -120px;
      right: -200px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      width: 500px;
      right: -250px;
      bottom: -180px;
    }
  }
`

const City = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1em;
  color: ${props => props.theme.colors.primary};

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 32px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    font-size: 36px;
  }
`

const Section = styled.section`
  padding: 40px 0 16px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 80px 0 40px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 140px 0 80px;
  }

  h1 {
    text-transform: uppercase;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin: 1em 0;
    color: ${props => props.theme.colors.primary};

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 22px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 26px;
    }
  }

  .lead {
    color: ${props => props.theme.colors.dark};
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    a {
      margin-top: 40px;
    }
  }
`

export { HeaderImage, City, Section }
